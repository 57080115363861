<template>
    <div
        class="flex items-center bg-gray-100 w-screen h-screen bg-cover"
        :style="{
            'background-image': `url(${require('../assets/loading_wallpaper.jpg')})`
        }"
    >
        <div class="w-full py-16 bg-gray-400 opacity-95 flex justify-center">
            <div>
                <div>
                    <img
                        class="mb-12 text-center"
                        src="@/assets/flow-light.png"
                        alt="Logo"
                    />
                </div>
                <Spinner />
                <div class="mt-12 font-bold text-white text-2xl">
                    Verifying
                    <span class="loading">
                        <span>.</span><span>.</span><span>.</span>
                    </span>
                </div>
            </div>
        </div>
    </div></template
>

<script>
import Spinner from "@/components/Spinner"
import { getSavedLocale } from "@/util/locate"

export default {
    name: "Redirect",
    components: { Spinner },
    mounted() {
        if (this.$route.query.reset_url && this.$route.query.user_id) {
            this.verifyUser(this.$route.query.user_id)
        } else {
            alert("Invalid verification request.")
            this.$router.push({
                name: "Dashboard",
                params: { lang: getSavedLocale() }
            })
        }
    },
    methods: {
        async verifyUser(uid) {
            const url = process.env.VUE_APP_API_BASE_URL + `/admin/verify`
            const data = {
                user_id: uid
            }
            this.$axios.put(url, data).then(res => {
                if (res.data.Success === true) {
                    window.location.href = decodeURIComponent(
                        this.$route.query.reset_url
                    )
                }
            })
        }
    }
}
</script>

<style scoped lang="css">
@keyframes blinkdot {
    0% {
        opacity: 0.2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}
.loading span {
    animation-name: blinkdot;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}
.loading span:nth-child(2) {
    animation-delay: 0.2s;
}
.loading span:nth-child(3) {
    animation-delay: 0.4s;
}
</style>
